import React from "react";
import "./Home.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const Home = () => {
    return (
        <div>
            <div className="introSection" id="introId">
                <Container>
                    <Row className="justify-content-center rowCol">
                        <Col lg={12}>
                            <p className="mainTitle">The Pink Blur</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <br />
            <h4>Copyright 2024 Andy O'Sullivan</h4>
            <br />
        </div>
    );
};

export default Home;
