import React from "react";
import './App.css';
import NewRoutes from "./NewRoutes";
import { BrowserRouter as Router  } from 'react-router-dom';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <Router>
            <div className="App bg-color">
                <ConditionalNavbar />
                <NewRoutes />
            </div>
        </Router>
    );
}

function ConditionalNavbar() {
    //const location = useLocation();

    // Check if the current path is "/donate"
    //const isDonatePage = location.pathname === '/donate';

    return (
        <Navbar id="navbarId" collapseOnSelect expand="lg" className="navbar" style={{ backgroundColor: '#ff00ff', color: "black"}}>
            <Navbar.Brand className="navBrand" href="/">The Pink Blur</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="navbar-expand-lg">
                        <Nav className="me-auto navMenu">
                            <br/>
                            <Nav.Link className="navLinks" href="" >CONTACT</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
        </Navbar>
    );
}



export default App;
